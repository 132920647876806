import React from "react";

const Text = ({ data }) => {
  const { values } = data;

  const getImageStyles = (align, isFitToScreen, backgroundHeight, backgroundWidth, backgroundColor = "", item, ) => {

    const baseStyles = {
      position: "absolute",
      zIndex: 0,
      // height: isFitToScreen ? "600px" : backgroundHeight,
      // width: isFitToScreen ? "336px" : backgroundWidth,
      backgroundColor: backgroundColor,
    };

    switch (align) {
      case "top left": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "top center": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "top right": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: 0,
          right: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      case "middle left": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "middle center": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "middle right": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      case "bottom left": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          // top:'50%',
          left: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "bottom center": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "bottom right": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      default:
        return baseStyles;
    }
  };
  return (
    <div style={{margin:10}} className="control_padding">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          return (
          <div
            key={ind}
            // style={{
            //   height: item?.textHeight,
            //   width: item?.textWidth,
            //   background: item?.textBackground,
            //   border: `1px solid ${item?.textBorder}`,
            //   color: item?.textColor,
            //   opacity: item?.textOpacity,
            //   // marginTop: "5px",
            //   padding: item?.textBackground ? "7px" : "0px",
            //   marginBottom: item?.marginBottom ? item?.marginBottom :  "10px",
            //   marginTop: item?.marginTop ,
            //   marginLeft: item?.marginLeft ,
            //   marginRight: item?.marginRight ,
            //   fontFamily: item?.textFont,
            //   fontSize: item?.fontSize,
            //   paddingTop: item?.paddingTop,
            //   paddingLeft: item?.paddingLeft,
            //   paddingBottom: item?.paddingBottom,
            //   paddingRight: item?.paddingRight,
            //   borderRadius: item?.textBorderRadius ? item?.textBorderRadius : "7px",
            //   position: item?.textXPosition ? "absolute" : "relative",
            //   top: item?.textXPosition ? item?.textXPosition : "10",
            //   [item?.textAlign || "left"]: item?.textYPosition
            //     ? item?.textYPosition
            //     : "0px",
            //   wordWrap: "break-word",
            //   // padding: "4px",
            // }}
            style={{
              ...getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item, ),
                                    background: item?.textBackground,
                                    borderRadius: item?.textBorderRadius ? `${item.textBorderRadius}px` : "",
                                    border: `1px solid ${item?.textBorder}`,
                                    color: item?.textColor,
                                    opacity: item?.textOpacity,
                                    fontFamily: item?.textFont,
                                    fontSize: item?.fontSize ? `${item.fontSize}` : "",
                                    height: item.textHeight,
                                    width: "100%",
                                    wordWrap: "break-word",

            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: item?.bodyText || "Text",
              }}
              className="editor-inner-text"
              // style={{
              //   margin: "4px 8px",
              //   opacity: item?.textOpacity,
              //   textAlign: item?.textAlign,
              //   color: item?.textColor,
              // }}
              style={{
              // margin: "4px 8px",
              opacity: item?.textOpacity,
              // textAlign: item?.textAlign,
              paddingLeft: item?.paddingLeft ? `${item.paddingLeft}px` : "",
              paddingRight: item?.paddingRight ? `${item.paddingRight}px` : "",
              paddingTop: item?.paddingTop ? `${item.paddingTop}px` : "",
              paddingBottom: item?.paddingBottom ? `${item.paddingBottom}px` : "",
              color: item?.textColor,
              fontSize: item?.fontSize ? `${item.fontSize}` : ""
            }}
            />
          </div>
        )
      })}
    </div>
  );
};

export default Text;
