import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, MarkerF } from "@react-google-maps/api";

const MultiLocationMap = ({ item, ind, handleSave, parentId }) => {
    const [locationText, setLocationText] = useState(item?.locationText);
    const [locationHoursText, setLocationHoursText] = useState(item?.locationHoursText);
    const [mapLocationList, setMapLocationList] = useState(item?.selectedLocationGroup?.scheduleLocationsList);
    const [currentLocation, setCurrentLocation] = useState({});
    const [locationCenter, setLocationCenter] = useState({ latitude: 0, longitude: 0 });

    useEffect(() => {
        let itemData = item?.selectedLocationGroup?.scheduleLocationsList[0];
        let fullAddress = `${itemData?.office}, ${itemData?.street} ${itemData?.city} ${itemData?.state} ${itemData?.zipcode}`
        setLocationText(fullAddress);
        setCurrentLocation(itemData);
    }, [item]);


    const handleClickOnDisplayAddress = (position, index) => {
        setCurrentLocation(position);
        let selectedLocation = mapLocationList.find((locationItem) => locationItem.primaryId === position.primaryId);
        let fullAddress = `${selectedLocation?.office}, ${selectedLocation?.street} ${selectedLocation?.city} ${selectedLocation?.state} ${selectedLocation?.zipcode}`
        setLocationText(fullAddress);
        setLocationCenter((prev) => ({ ...prev, latitude: selectedLocation?.latitude, longitude: selectedLocation?.longitude }));
        handleSave(
            {
              parentId: parentId,
              id: String(item?.id),
              locationId: position?.primaryId
            },
            "Map",
            false
        );
    }

    const getTimeFormate = (currTime) => {
        let normalCurrTime = currTime
        if (normalCurrTime?.includes("AM")) {
            normalCurrTime = normalCurrTime?.replace("AM", "")?.trim();
        } else if (normalCurrTime?.includes("PM")) {
            normalCurrTime = normalCurrTime?.replace("PM", "")?.trim();
        }
        let splittedTime = normalCurrTime?.split(":");
        const time = new Date();
        if (splittedTime && splittedTime.length > 0) {
            time?.setHours(splittedTime[0]);
            time?.setMinutes(splittedTime[1]);
        }
        const formattedTime = time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        return formattedTime
    }

    const removeHtmlTags = (str) => {
        return str.replace(/<\/?[^>]+>/gi, '');
    };

    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const getDate = () => {
        let s = new Date().getSeconds();
        let d = randomNumberInRange(1, 1000);
        return `${d}${s}`;
    };

    return (
        <div className="mb-2 pt-2" style={{
            marginTop: item?.mapMarginTop ? `${item?.mapMarginTop}px` : "0px",
            marginRight: item?.mapMarginRight ? `${item?.mapMarginRight}px` : "0px",
            marginBottom: item?.mapMarginBottom ? `${item?.mapMarginBottom}px` : "0px",
            marginLeft: item?.mapMarginLeft ? `${item?.mapMarginLeft}px` : "0px"
        }}>
            {/* {(item.latitude && item.longitude) ? */}
                <GoogleMap
                    zoom={8}
                    mapContainerStyle={{
                        height: "250px",
                        width: "100%",
                        borderRadius: item?.borderRadius ? `${item?.borderRadius}px` : "10px",
                        border: `1px solid ${item?.borderColor}`,
                        overflow: "hidden"
                    }}
                    center={{
                        lat: (locationCenter?.latitude != "") ? locationCenter?.latitude : mapLocationList[0]?.latitude ? mapLocationList[0]?.latitude : 0,
                        lng: (locationCenter?.longitude != "") ? locationCenter?.longitude : mapLocationList[0]?.longitude ? mapLocationList[0]?.longitude : 0
                    }}
                >
                    {mapLocationList.map((position, index) => {
                        let mapKey = getDate(index);
                        return (
                            <Marker
                                key={mapKey}
                                position={{ 
                                    // lat: position?.latitude, lng: position?.longitude
                                    lat: (position?.latitude != "") ? position?.latitude : mapLocationList[0]?.latitude,
                                    lng: (position?.longitude != "") ? position?.longitude : mapLocationList[0]?.longitude 
                                }}
                                onClick={() => handleClickOnDisplayAddress(position, index)}
                                // title={`${item?.office}, ${item?.street} ${item?.city} ${item?.state} ${item?.zipcode}`}
                            >
                                {/* {(showInfo && showInfo !== "" && showInfo?.index === index) && (
                                    <InfoWindow onCloseClick={() => setShowInfo(null)}>
                                        <span>{showInfo?.info}</span>
                                    </InfoWindow>
                                )} */}
                            </Marker>
                        )
                    })}
                </GoogleMap>
                {/* :
                <div
                    style={{
                        height: "250px",
                        width: "100%",
                        borderRadius: item?.borderRadius ? `${item?.borderRadius}px` : "10px",
                        background: "#e5e5e5",
                        border: `1px solid ${item?.borderColor}`
                    }}
                >

                </div>
            } */}

            <div className="d-flex mt-2 justify-content-between">
                <div
                    style={{
                        width: "45%",
                        marginTop: item?.textMarginTop ? `${item?.textMarginTop}px` : "0px",
                        marginRight: item?.textMarginRight ? `${item?.textMarginRight}px` : "0px",
                        marginBottom: item?.textMarginBottom ? `${item?.textMarginBottom}px` : "0px",
                        marginLeft: item?.textMarginLeft ? `${item?.textMarginLeft}px` : "0px",
                        paddingTop: item?.paddingTop ? `${item?.paddingTop}px` : "0px",
                        paddingRight: item?.paddingRight ? `${item?.paddingRight}px` : "0px",
                        paddingBottom: item?.paddingBottom ? `${item?.paddingBottom}px` : "0px",
                        paddingLeft: item?.paddingLeft ? `${item?.paddingLeft}px` : "0px"
                    }}>
                    <p
                        style={{
                            color: item?.locationTextColor, fontWeight: "bold",
                            fontSize: item?.locationTextSize,
                            fontFamily: item?.locationTextFont,
                        }}
                    >Address</p>
                    <p
                        style={{
                            color: item?.locationTextColor,
                            fontSize: item?.locationTextSize,
                            fontFamily: item?.locationTextFont,
                        }}
                    >{!(locationText?.includes("undefined")) ? locationText : ""}</p>
                </div>

                <div
                    style={{
                        width: "45%",
                        marginTop: item?.locationHoursMarginTop ? `${item?.locationHoursMarginTop}px` : "0px",
                        marginRight: item?.locationHoursMarginRight ? `${item?.locationHoursMarginRight}px` : "0px",
                        marginBottom: item?.locationHoursMarginBottom ? `${item?.locationHoursMarginBottom}px` : "0px",
                        marginLeft: item?.locationHoursMarginLeft ? `${item?.locationHoursMarginLeft}px` : "0px",
                        paddingTop: item?.locationHoursPaddingTop ? `${item?.locationHoursPaddingTop}px` : "0px",
                        paddingRight: item?.locationHoursPaddingRight ? `${item?.locationHoursPaddingRight}px` : "0px",
                        paddingBottom: item?.locationHoursPaddingBottom ? `${item?.locationHoursPaddingBottom}px` : "0px",
                        paddingLeft: item?.locationHoursPaddingLeft ? `${item?.locationHoursPaddingLeft}px` : "0px"
                    }}>
                    <div>
                        <div>
                            <p
                                style={{
                                    color: item?.locationHourTextColor, fontWeight: "bold",
                                    fontSize: item?.locationTextSize,
                                    fontFamily: item?.locationTextFont,
                                }}
                            >Hours</p>
                            <div>
                                <div
                                    style={{
                                        color: item?.locationHourTextColor,
                                        fontSize: item?.locationHourTextSize,
                                        fontFamily: item?.locationTextFont
                                    }}
                                >
                                    <span style={{ textWrap: "nowrap" }}>{getTimeFormate(currentLocation?.weekDayOpen)} - </span>
                                    <span className="text-nowrap">{getTimeFormate(currentLocation?.weekDayClose)} &nbsp;</span>
                                    <p className="text-capitalize"
                                        style={{
                                            color: item?.locationHourTextColor,
                                            fontSize: item?.locationHourTextSize,
                                            fontFamily: item?.locationTextFont
                                        }}
                                    >MON-FRI</p>
                                </div>
                                {(currentLocation?.saturdayOpen && currentLocation?.saturdayClose) &&
                                    <div
                                        style={{
                                            color: item?.locationHourTextColor,
                                            fontSize: item?.locationHourTextSize,
                                            fontFamily: item?.locationTextFont
                                        }}
                                    >
                                        <span style={{ textWrap: "nowrap" }}>{getTimeFormate(currentLocation?.saturdayOpen)} - </span>
                                        <span>{getTimeFormate(currentLocation?.saturdayClose)}</span>
                                        <span> SAT-SUN</span>
                                    </div>
                                }
                                {(currentLocation?.holidays === "no") &&
                                    <div
                                        style={{
                                            color: item?.locationHourTextColor,
                                            fontSize: item?.locationHourTextSize,
                                            fontFamily: item?.locationTextFont
                                        }}
                                    >
                                        <p>Holidays: Closed </p>
                                    </div>
                                }
                                {/* <div><span>{getTimeFormate(currentLocation?.WeekEnd)}</span></div> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* <p className="address_info_title">Phone</p> */}
                        <p className="address_info_desc" 
                            style={{
                                color: item?.locationHourTextColor,
                                fontSize: item?.locationHourTextSize,
                                fontFamily: item?.locationTextFont,
                                textDecoration: "underline"
                            }}
                        >{currentLocation?.phoneNumber}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultiLocationMap;