import React from "react";

const Background = ({ data }) => {
    const { values } = data;

    const getImageStyles = (align, isFitToScreen, backgroundHeight, backgroundWidth, backgroundColor = "", item) => {
        const baseStyles = {
          position: "absolute",
          zIndex: 0,
          height: isFitToScreen ? "100vh" : backgroundHeight,
          width: isFitToScreen ? "100vw" : backgroundWidth,
          backgroundColor: backgroundColor,
        };
    
        switch (align) {
          case "top left": {
            const marginTop = item?.marginTop || 0;
            const marginBottom = item?.marginBottom || 0;
            const marginLeft = item?.marginLeft || 0;
            const marginRight = item?.marginRight || 0;
    
            const adjustedMarginTop = marginTop - marginBottom;
            const adjustedMarginLeft = marginLeft - marginRight;
    
            return {
              ...baseStyles,
              top: 0,
              left: 0,
              marginTop: `${adjustedMarginTop}px`,
              marginLeft: `${adjustedMarginLeft}px`
            };
          }
          case "top center": {
            const marginTop = item?.marginTop || 0;
            const marginBottom = item?.marginBottom || 0;
            const marginLeft = item?.marginLeft || 0;
            const marginRight = item?.marginRight || 0;
    
            const adjustedMarginTop = marginTop - marginBottom;
            const adjustedMarginLeft = marginLeft - marginRight;
    
            return {
              ...baseStyles,
              top: 0,
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: `${adjustedMarginTop}px`,
              marginLeft: `${adjustedMarginLeft}px`
            };
          }
          case "top right": {
            const marginTop = item?.marginTop || 0;
            const marginBottom = item?.marginBottom || 0;
            const marginRight = item?.marginRight || 0;
            const marginLeft = item?.marginLeft || 0;
    
            const adjustedMarginTop = marginTop - marginBottom;
            const adjustedMarginRight = marginRight - marginLeft;
    
            return {
              ...baseStyles,
              top: 0,
              right: 0,
              marginTop: `${adjustedMarginTop}px`,
              marginRight: `${adjustedMarginRight}px`
            };
          }
          case "middle left": {
            const marginTop = item?.marginTop || 0;
            const marginBottom = item?.marginBottom || 0;
            const marginLeft = item?.marginLeft || 0;
            const marginRight = item?.marginRight || 0;
    
            const adjustedMarginTop = marginTop - marginBottom;
            const adjustedMarginLeft = marginLeft - marginRight;
    
            return {
              ...baseStyles,
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
              marginTop: `${adjustedMarginTop}px`,
              marginLeft: `${adjustedMarginLeft}px`
            };
          }
          case "middle center": {
            const marginTop = item?.marginTop || 0;
            const marginBottom = item?.marginBottom || 0;
            const marginLeft = item?.marginLeft || 0;
            const marginRight = item?.marginRight || 0;
    
            const adjustedMarginTop = marginTop - marginBottom;
            const adjustedMarginLeft = marginLeft - marginRight;
    
            return {
              ...baseStyles,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              marginTop: `${adjustedMarginTop}px`,
              marginLeft: `${adjustedMarginLeft}px`
            };
          }
          case "middle right": {
            const marginTop = item?.marginTop || 0;
            const marginBottom = item?.marginBottom || 0;
            const marginRight = item?.marginRight || 0;
            const marginLeft = item?.marginLeft || 0;
    
            const adjustedMarginTop = marginTop - marginBottom;
            const adjustedMarginRight = marginRight - marginLeft;
    
            return {
              ...baseStyles,
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
              marginTop: `${adjustedMarginTop}px`,
              marginRight: `${adjustedMarginRight}px`
            };
          }
          case "bottom left": {
            const marginBottom = item?.marginBottom || 0;
            const marginTop = item?.marginTop || 0;
            const marginLeft = item?.marginLeft || 0;
            const marginRight = item?.marginRight || 0;
    
            const adjustedMarginBottom = marginBottom - marginTop;
            const adjustedMarginLeft = marginLeft - marginRight;
    
            return {
              ...baseStyles,
              bottom: 0,
              left: 0,
              marginBottom: `${adjustedMarginBottom}px`,
              marginLeft: `${adjustedMarginLeft}px`
            };
          }
          case "bottom center": {
            const marginBottom = item?.marginBottom || 0;
            const marginTop = item?.marginTop || 0;
            const marginLeft = item?.marginLeft || 0;
            const marginRight = item?.marginRight || 0;
    
            const adjustedMarginBottom = marginBottom - marginTop;
            const adjustedMarginLeft = marginLeft - marginRight;
    
            return {
              ...baseStyles,
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              marginBottom: `${adjustedMarginBottom}px`,
              marginLeft: `${adjustedMarginLeft}px`
            };
          }
          case "bottom right": {
            const marginBottom = item?.marginBottom || 0;
            const marginTop = item?.marginTop || 0;
            const marginRight = item?.marginRight || 0;
            const marginLeft = item?.marginLeft || 0;
    
            const adjustedMarginBottom = marginBottom - marginTop;
            const adjustedMarginRight = marginRight - marginLeft;
    
            return {
              ...baseStyles,
              bottom: 0,
              right: 0,
              marginBottom: `${adjustedMarginBottom}px`,
              marginRight: `${adjustedMarginRight}px`
            };
          }
          default:
            return baseStyles;
        }
    };

    return (
        <div>
            {values &&
                values.length > 0 &&
                values?.map((item, ind) => (
                    <React.Fragment key={ind}>
                        {(!item?.isFitToScreen && item?.imageData) ?
                            <>
                                <div style={{
                                    height: "100%",
                                    width: "100%",
                                    left: 0,
                                    top: 0,
                                    position: "absolute"
                                }}>
                                    <div style={{ position: "relative", width: "100%", height: "100%" }}>
                                        {item?.imageData &&
                                            <img
                                                src={item?.imageData}
                                                alt=""
                                                style={getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, "", item)}
                                            />
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div style={{
                                    height: "100%",
                                    width: "100%",
                                    left: 0,
                                    top: 0,
                                    position: "absolute"
                                }}>
                                    <div style={{ position: "relative", width: "100%", height: "100%" }}>
                                        {item?.background &&
                                            <div
                                                style={getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item)}
                                            >
                                            </div>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </React.Fragment>
                ))}
        </div>
    );
};

export default Background;


