import React, { useEffect, useState, useRef } from "react";
import "./../index.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { selectFitResponse } from "../../redux/store/fit/fitSelector";
import {
  addFitResp,
  getFitResponseAction,
} from "../../redux/store/fit/fitAction";
import {
  setError,
  setLoader,
  setTokenError,
} from "../../redux/store/loader/loaderAction";
import PlayIcon from "../../assets/images/playIcon.png";
import { decodeToken } from "../../utils";

const FitAfricanAmerican = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const campVideoRef = useRef(null);
  const fitResponse = useSelector(selectFitResponse);
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    dispatch(setError(""));
    const token = queryParams.get("token");
    var data = decodeToken(token);

    if (!token) {
      dispatch(setError("token is missing. Please contact Admin"));
      dispatch(setLoader(false));
      return;
    } else {
      localStorage.setItem("token", token);
      dispatch(getFitResponseAction({ id: data.uid, campCode: data.campCode }));

      if (fitResponse === null || fitResponse?.watchVideo !== "Y") {
         handleSendFITKit("Y","N");
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", function (e) {
      if (document.getElementById("modalId")) {
        campVideoRef.current.pause();
      }
    });
  }, []);

  useEffect(() => {
    const handleModalClose = () => {
      campVideoRef.current.pause();
    };
    const modalElement = document.getElementById("staticBackdrop");
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleModalClose);
    }
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
      }
    };
  }, []);

  const handleSendFITKit = (resDe, viewvideo) => {
    const token = queryParams.get("token");
    const user = decodeToken(token);
    const data = {
      userGuid: user.uid,
      campaignCode: user.scheduleCode,
      responseDe: resDe,
      watchVideo: viewvideo,
    };
    dispatch(addFitResp(data));
  };

  const handlePlayPause = () => {
    if (fitResponse?.watchVideo === "N") {
      handleSendFITKit("Y", "Y");
    }

    if (campVideoRef.current) {
      if (campVideoRef.current.paused) {
        let playPromise = campVideoRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              campVideoRef.current.play();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        campVideoRef.current.pause();
      }
    }
  };
  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help">
        <div className="card-header bg-inky text-white text-center">
          <img className=" w-15" src="../../../Logo.svg" />
        </div>
        <div className="card-body card-body-h-vh bg-fit-afam bg-fit-card-one p-0 container-340 ">
          <div className="fit_af_am">
            <div className="d-flex text-left mt-3 mb-5" style={{width:"157px"}}>
              <span className="fit_dyk">
                Did you
                 know...
              </span>
            </div>
            <div >
              <div className="d-flex justify-content-center mt-0">
                <div className="card-thanks text-white card-help-body mb-0 p-3" >
                  <p className="mb-0 text-left lbl_medium">
                    Colon cancer is the third leading cancer killer in the U.S.
                    among Black and African American people. Colon cancer can be
                    caught early or even prevented with routine screening.
                    Getting regular screening can save your life.
                  </p>
                  <p className="text-center mb-0 mt-3 lbl_medium">
                      Please complete the FIT kit we previously sent.
                  </p>
                </div>
              </div>

              <div className="mt-1 mb-1 p-2">
                <div
                  className="d-flex"
                  style={{
                    border: `1px solid #ccc`,
                    padding: "5px",
                    borderRadius: "6px",
                    backgroundColor: "#fff"
                  }}
                >
                  <div style={{ width: "65%" }}>
                    <h4
                      className="mb-0 px-2"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        textWrap: "nowrap",
                        paddingRight: "1rem",
                        color: "#0D1C3D",
                        paddingBottom: "0.3rem",
                        fontSize: "2.2rem",
                      }}
                    >
                      Watch…
                    </h4>
                    <p className="mb-2 text-left fitv2-videodes px-2">
                      Rick's story - “My family means the world to me...”
                    </p>
                    <img
                      className="px-2"
                      alt="img "
                      src="https://elxovoiceurl.blob.core.windows.net/images/b4c976a9-c9b1-48eb-a128-b0ed5107d73a.svg"
                      style={{ height: "auto", width: "5rem" }}
                    />
                  </div>

                  <div
                    style={{
                      width: "35%",
                      border: "1px solid #000",
                      borderRadius: "5px",
                      position: "relative",
                      maxHeight: "200px",
                    }}
                  >
                    <img
                      alt="img "
                      src="https://elxovoiceurl.blob.core.windows.net/images/Rick_thumbnail-100%20(1).jpg"
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        height: "100%",
                      }}
                    />
                    <img
                      alt="img"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      onClick={() => handlePlayPause()}
                      src={PlayIcon}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "50px",
                        height: "50px",
                        zIndex: 999,
                      }}
                    />
                  </div>
                </div>
                <div
                  className="modal fade custom_fade_model"
                  id="staticBackdrop"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div
                        className="modal-body p-0"
                        id="modalId"
                        style={{ height: "50vh", backgroundColor: "#000" }}
                      >
                        <video
                          ref={campVideoRef}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                          className="video-element"
                          controls
                        >
                          <source
                            src="https://elxoscheduler.blob.core.windows.net/elxovideos/RicksStory.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-1">
                <div className="">
                  <p className="text-center mb-0 lbl_medium p-3 fit_question">
                    Questions? Please contact your primary care provider.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FitAfricanAmerican;
