import React, { useEffect, useState } from 'react';
import "./ColoSummary.css"
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    setError,
    setLoader,
    setTokenError,
} from "../../redux/store/loader/loaderAction";
import { decodeToken } from "../../utils";
import Footer from './components/Footer';
import ResourceLink from './components/ResourceLink';

const ColoSummary = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [msgId, setMsgId] = useState(0);
    const token = queryParams.get("token");

    useEffect(() => {
        dispatch(setError(""));
        if (!token) {
            dispatch(setError("token is missing. Please contact Admin"));
            dispatch(setLoader(false));
            return;
        }
        else {
            const data = decodeToken(token);
            setMsgId(data.MsgID);
        }
    }, []);

    const getStepContainerCssClass = (stepContainerId) => {
        if(stepContainerId == 1){
            if(msgId >= 1 && msgId <= 9){
                return ""
            }
            else{
                return "d-none";
            }
        }
        if(stepContainerId == 2){
            if(msgId >= 19 && msgId <= 26){
                return ""
            }
            else{
                return "d-none";
            }
        }
    }

    const getActiveCssClass = (currentId) => {
        if (msgId == currentId) {
            return " active";
        }
        else if (msgId < currentId) {
            return " preview"
        }
        else {
            return " completed";
        }
    }

    const getActionText = (currentId) => {
        if (msgId == currentId) {
            return "Get Started";
        }
        else if (currentId < msgId) {
            return "Completed";
        }
        else {
            return "Preview"
        }
    }

    const redirectToDetailsPage = (currentPage) => {
        window.location = `/kp/colo/details/${currentPage}?token=${token}`;
    }

    return (
        <>
            <div className='container-fluid g-0'>
                <div className='card card-custom card-help'>
                    <div className='card-header bg-blue text-white text-center'>
                        <img class=" w-15" src="../../../Logo.svg" />
                    </div>
                    <div className="container-fluid">
                        <div className="steps-page-heading mb-4">
                            <h5 className="p_header_text text-inky-blue text-start mt-4">Prepare</h5>
                            <p className="text-inky-blue fs-6">This is your electronic guide to preparing for your upcoming colonoscopy. It will support and guide you in the coming weeks so you can be as prepared as possible.</p>
                        </div>
                        <div className="stepper-size-320">
                            <div className={"stepper-conatainer " + getStepContainerCssClass(1)}>
                                <div className={"steps-group " + getActiveCssClass(1)}>
                                    <div className="steps-circle">1</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">Three weeks to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(1)}>{getActionText(1)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(2)}>
                                    <div className="steps-circle">2 </div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">Two weeks go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(2)}>{getActionText(2)}
                                        </button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(3)}>
                                    <div className="steps-circle">3</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">7 days to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(3)}>{getActionText(3)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(4)}>
                                    <div className="steps-circle">4</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">5 days to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(4)}>{getActionText(4)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(5)}>
                                    <div className="steps-circle">5</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">3 days to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(5)}>{getActionText(5)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(6)}>
                                    <div className="steps-circle">6</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">2 days to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(6)}>{getActionText(6)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(7)}>
                                    <div className="steps-circle">7</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">1 day to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(7)}>{getActionText(7)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(8)}>
                                    <div className="steps-circle">8</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">Day of your procedure</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(8)}>{getActionText(8)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group pb-3 " + getActiveCssClass(9)}>
                                    <div className="steps-circle">9</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">Day of your procedure</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(9)}>{getActionText(9)}</button>
                                    </div>
                                </div>
                            </div>
                            <div className={"stepper-conatainer " + getStepContainerCssClass(2)}>
                                <div className={"steps-group " + getActiveCssClass(19)}>
                                    <div className="steps-circle">1</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">Three weeks to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(19)}>{getActionText(19)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(20)}>
                                    <div className="steps-circle">2 </div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">Two weeks go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(20)}>{getActionText(20)}
                                        </button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(21)}>
                                    <div className="steps-circle">3</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">7 days to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(21)}>{getActionText(21)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(22)}>
                                    <div className="steps-circle">4</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">5 days to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(22)}>{getActionText(22)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(23)}>
                                    <div className="steps-circle">5</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">3 days to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(23)}>{getActionText(23)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(24)}>
                                    <div className="steps-circle">7</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">1 day to go</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(24)}>{getActionText(24)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group " + getActiveCssClass(25)}>
                                    <div className="steps-circle">8</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">Day of your procedure</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(25)}>{getActionText(25)}</button>
                                    </div>
                                </div>
                                <div className={"steps-group pb-3 " + getActiveCssClass(26)}>
                                    <div className="steps-circle">9</div>
                                    <div className="d-flex flex-wrap flex-column steps-details-list">
                                        <h6 className="stepper-label text-inky-blue fw-bold">Day of your procedure</h6>
                                        <button className="btn btn-outline-primary btn btn-outline-primary-steps " onClick={() => redirectToDetailsPage(26)}>{getActionText(26)}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='marginBottom25Rem'>
                            <h6 className='p_header_text text-inky-blue text-start paddingLeft15px'>Additional Resources</h6>
                            <ResourceLink data={{ "marginTop":"mt-3", "paddingLeft":"marginLeft9px","Img": "Apple.svg", "Href": "FAQs.pdf", "Text": "Frequently asked questions" }} />
                            <ResourceLink data={{ "marginTop":"mt-0", "paddingLeft":"marginLeft9px","Img": "Apple.svg", "Href": "DiabetesMedsGuide.pdf", "Text": "Diabetes medication questions" }} />
                        </div>
                        <Footer paddingLeft="paddingLeft15px"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ColoSummary