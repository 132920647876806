import React, { useEffect, useState } from "react";
import "./../index.css";
const FITKIT2 = () => {
  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help">
        <div className="card-header bg-inky text-white text-center">
          <img alt="logo" className=" w-15" src="../../Logo.svg" />
        </div>
        <div className="card-body card-body-h-vh bg-fit-card2 bg-fit-card-one p-0 container-340 ">
          <div className="mt-auto mb-5">
            <div className="card-thanks text-white card-help-body mb-5 p-1">
              <p className="mb-0 text-center lbl_medium" style={{lineHeight:30+"px"}}>
              Please complete the FIT kit we previously sent. This screens for colon cancer, and needs to be repeated yearly to work. 
              </p>
              <br/>
              <p className="text-center mb-0 lbl_medium mb-1" style={{lineHeight:30+"px"}}>
                It takes just a few minutes and <span style={{textDecoration: "underline"}}>can save your life.</span>
              </p>
            </div>
            <div style={{ borderWidth:1, backgroundColor: "#fff", margin:'auto', width:"75%", borderRadius:12 }} >
            <div className="d-flex justify-content-center mt-5">
              <div >
                <p className="text-center mb-0  p-1 fit_question fit_question_text">Questions? Please contact your 
                  primary care provider.</p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FITKIT2;
