import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import MapItem from "./MapItem";
import MultiLocationMap from "./MultiLocationMap";

const MapComponent = ({ data, handleSave }) => {
  const { values } = data;

  const getImageStyles = (align, isFitToScreen, backgroundHeight, backgroundWidth, backgroundColor = "", item,) => {

    const baseStyles = {
      position: "absolute",
      zIndex: 0,
      height: isFitToScreen ? "600px" : backgroundHeight,
      width: isFitToScreen ? "336px" : backgroundWidth,
      backgroundColor: backgroundColor,
    };

    switch (align) {
      case "top left": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "top center": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "top right": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: 0,
          right: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      case "middle left": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "middle center": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "middle right": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      case "bottom left": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "bottom center": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "bottom right": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      default:
        return baseStyles;
    }
  };

  return (
    <div className="control_padding">
      {/* {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          return(
            <MapItem key={ind} item={item} ind={ind} />
        )})} */}

      {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          if(item?.locationSetting === "location list"){
            return (
              <div
                style={{
                  ...getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item, item?.type, false),
                  width: "100%", paddingLeft: "5px", paddingRight: "5px"
                }}
              >
                <MultiLocationMap key={ind} item={item} ind={ind} parentId={data.id} handleSave={handleSave} />
              </div>
              
            )
          }else{
            return (
              <div 
                style={{
                  ...getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item, item?.type, false),
                  width: "100%", paddingLeft: "5px", paddingRight: "5px"
                }}
              >
                <MapItem key={ind} item={item} ind={ind} parentId={data.id} handleSave={handleSave} />
              </div>
              
            )
          }
      })}
    </div>
  );
};

export default MapComponent;
