import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";


const MapItem = ({ item, ind, parentId, handleSave }) => {
    
    const handleClickOnDisplayAddress = () => {
        handleSave(
            {
              parentId: parentId,
              id: String(item?.id),
              locationId: "",
            },
            "Map",
            false
        );
    }
    
    return (
        <div className="mb-2 pt-2"
            style={{
                marginTop: item?.mapMarginTop ? `${item?.mapMarginTop}px` : "0px",
                marginRight: item?.mapMarginRight ? `${item?.mapMarginRight}px` : "0px",
                marginBottom: item?.mapMarginBottom ? `${item?.mapMarginBottom}px` : "0px",
                marginLeft: item?.mapMarginLeft ? `${item?.mapMarginLeft}px` : "0px"
            }}
        >
            {((item?.latitude && item?.longitude) || (item?.xCoordinatePosition && item?.yCoordinatePosition)) ?
                <GoogleMap
                    center={{
                        lat: item?.xCoordinatePosition ? Number(item?.xCoordinatePosition) : item?.latitude,
                        lng: item?.yCoordinatePosition ? Number(item?.yCoordinatePosition) : item?.longitude,
                    }}
                    zoom={8}
                    mapContainerStyle={{
                        height: "250px",
                        width: "100%",
                        // borderRadius: "12px",
                        borderRadius: item?.borderRadius ? `${item?.borderRadius}px` : "10px",
                        border: `1px solid ${item?.borderColor}`
                    }}
                >
                    {((item?.latitude && item?.longitude) || (item?.xCoordinatePosition && item?.yCoordinatePosition)) &&
                        <Marker
                            position={{
                                lat: item?.xCoordinatePosition ? Number(item?.xCoordinatePosition) : item?.latitude,
                                lng: item?.yCoordinatePosition ? Number(item?.yCoordinatePosition) : item?.longitude,
                            }}
                            onClick={() => handleClickOnDisplayAddress()}
                        ></Marker>
                    }
                </GoogleMap>
                :
                <div style={{
                    height: "250px",
                    width: "100%",
                    borderRadius: item?.borderRadius ? `${item?.borderRadius}px` : "10px",
                    background: "#e5e5e5",
                    border: `1px solid ${item?.borderColor}`
                }}>

                </div>
            }

            <div className="d-flex mt-2 justify-content-between">
                <p
                    dangerouslySetInnerHTML={{
                        __html: item?.locationText,
                    }}
                    style={{
                        fontSize: "1rem",
                        lineHeight: "20px",
                        marginTop: "8px",
                        width: "45%",
                        color: item?.locationTextColor,
                        fontSize: item?.locationTextSize,
                        fontFamily: item?.locationTextFont,
                        marginTop: item?.textMarginTop ? `${item?.textMarginTop}px` : "0px",
                        marginRight: item?.textMarginRight ? `${item?.textMarginRight}px` : "0px",
                        marginBottom: item?.textMarginBottom ? `${item?.textMarginBottom}px` : "0px",
                        marginLeft: item?.textMarginLeft ? `${item?.textMarginLeft}px` : "0px",
                        paddingTop: item?.paddingTop ? `${item?.paddingTop}px` : "0px",
                        paddingRight: item?.paddingRight ? `${item?.paddingRight}px` : "0px",
                        paddingBottom: item?.paddingBottom ? `${item?.paddingBottom}px` : "0px",
                        paddingLeft: item?.paddingLeft ? `${item?.paddingLeft}px` : "0px"
                    }}
                />

                {item?.locationHoursControl === "yes" &&
                    <p
                        dangerouslySetInnerHTML={{
                            __html: item?.locationHoursText,
                        }}
                        style={{
                            fontSize: "1rem",
                            lineHeight: "20px",
                            marginTop: "8px",
                            width: "45%",
                            color: item?.locationHourTextColor,
                            fontSize: item?.locationHourTextSize,
                            fontFamily: item?.locationHourTextFont,
                            marginTop: item?.locationHoursMarginTop ? `${item?.locationHoursMarginTop}px` : "0px",
                            marginRight: item?.locationHoursMarginRight ? `${item?.locationHoursMarginRight}px` : "0px",
                            marginBottom: item?.locationHoursMarginBottom ? `${item?.locationHoursMarginBottom}px` : "0px",
                            marginLeft: item?.locationHoursMarginLeft ? `${item?.locationHoursMarginLeft}px` : "0px",
                            paddingTop: item?.locationHoursPaddingTop ? `${item?.locationHoursPaddingTop}px` : "0px",
                            paddingRight: item?.locationHoursPaddingRight ? `${item?.locationHoursPaddingRight}px` : "0px",
                            paddingBottom: item?.locationHoursPaddingBottom ? `${item?.locationHoursPaddingBottom}px` : "0px",
                            paddingLeft: item?.locationHoursPaddingLeft ? `${item?.locationHoursPaddingLeft}px` : "0px"
                        }}
                    />
                }
            </div>
        </div>
    )
}


export default MapItem;