export const addFitResp = (data) => {
    return {
      type: 'ADD_FIT_RESP',
      payload: data,
    };
  };
  
  export const getFitResponseAction = (data) => {
    return {
      type: 'GET_FIT_RESP',
      payload: data,
    };
  };
  
  export const setFitResponse = (data) => {
    return {
        type: 'SET_FIT_RESP',
        payload: data,
    };
  };
  
  

  
 
  
  
  